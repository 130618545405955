.table {
    /*border: 1px solid var(--col-grey-200);*/
    min-width: 100%;
    border-collapse: separate;
    border-spacing: 0;
}

.table thead {
    position: sticky;
    top: 0;
    z-index: 10;
}

.table thead th {
    text-transform: uppercase;
    color: var(--col-primary-50);
    font-size: var(--fs-9);
    letter-spacing: -0.2px;
    background: var(--col-primary-500);
    border-top: 1px solid var(--col-primary-600);
    border-left: 1px solid var(--col-primary-600);
}

.table thead th:last-of-type {
    border-right: 1px solid var(--col-primary-600);
}

.table thead tr:last-of-type th {
    border-bottom: 1px solid var(--col-primary-600);
}

.table .table-secondary-header th {
    color: var(--col-primary-100);
    font-weight: normal;
    text-transform: initial;
}

.table td {
    border-left: 1px solid var(--col-grey-100);
    border-top: 1px solid var(--col-grey-100);
}

.table td:last-of-type {
    border-right: 1px solid var(--col-grey-100);
}

.table tr:last-of-type td {
    border-bottom: 1px solid var(--col-grey-100);
}

.table td,
.table th {
    text-align: left;
    padding: 4px 4px;
    /*border: 1px solid var(--col-grey-200);*/
}

.table tr:nth-of-type(odd) td {
    background: var(--col-primary-50);
}

.table td {
    /*overflow-y: auto;*/
}

.table tr:hover + tr td {
    border-top-color: var(--col-primary-200);
}

.table tr:hover td {
    border-color: var(--col-primary-200);
    background: var(--col-primary-100);
}

.table td .button {
    height: 24px;
    line-height: 24px;
}

.table .tbl-center {
    text-align: center;
}

.table .tbl-fit {
    width: 0.1%;
    white-space: nowrap;
}

.table .tbl-align-right {
    text-align: end;
    font-variant-numeric: tabular-nums;
}

.table .tbl-align-center {
    text-align: center;
}

.table .tbl-inverted.tbl-inverted.tbl-inverted {
    background: var(--col-primary-500);
    color: white;
}

.td-editable {
    position: relative;
    overflow: hidden;
}

.td-editable-indicator {
    position: absolute;
    bottom: 0;
    right: -7px;
    width: 0;
    height: 0;
    border: 7px solid transparent;
    border-bottom-color: var(--col-primary-200);
}

.table tfoot {
    position: sticky;
    bottom: 0;
}

.pagination > * {
    min-width: var(--u-32);
    text-align: center;
}

td .pagination .button {
    height: initial;
}
