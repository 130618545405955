@import './tables.css';
@import './components.css';
@import './forms.css';
@import './bays.css';

@import url('https://fonts.googleapis.com/css2?family=News+Cycle:wght@400;700&display=swap');

:root {
    /* Color palettes used */
    --col-grey-900: #111111;
    --col-grey-800: #262626;
    --col-grey-700: #404040;
    --col-grey-600: #555555;
    --col-grey-500: #6f6f6f;
    --col-grey-400: #909090;
    --col-grey-300: #aaaaaa;
    --col-grey-200: #cfcfcf;
    --col-grey-100: #eeeeee;
    --col-grey-50: #f8f8f8;
    --col-white: #fff;

    /*--col-primary-900: #130306;*/
    /*--col-primary-800: #3A0912;*/
    /*--col-primary-700: #751224;*/
    /*--col-primary-600: #9C1830;*/
    /*--col-primary-500: #C41E3C;*/
    /*--col-primary-400: #CF4B63;*/
    /*--col-primary-300: #DB788A;*/
    /*--col-primary-200: #E7A5B1;*/
    /*--col-primary-100: #F3D2D8;*/
    /*--col-primary-50: #F9E8EB;*/

    --col-primary-900: #101f3f;
    --col-primary-800: #1b3260;
    --col-primary-700: #254a87;
    --col-primary-600: #2d62a9;
    --col-primary-500: #357dc0;
    --col-primary-400: #479ed3;
    --col-primary-300: #7cc2e7;
    --col-primary-200: #afe0f5;
    --col-primary-100: #daf1fa;
    --col-primary-50: #f3f8f9;

    --col-orange-900: hsl(25deg, 100%, 10%);
    --col-orange-800: hsl(30deg, 90%, 20%);
    --col-orange-700: hsl(33deg, 80%, 30%);
    --col-orange-600: hsl(34deg, 75%, 40%);
    --col-orange-500: hsl(34deg, 75%, 50%);
    --col-orange-400: hsl(34deg, 80%, 58%);
    --col-orange-300: hsl(37deg, 85%, 65%);
    --col-orange-200: hsl(39deg, 90%, 70%);
    --col-orange-100: hsl(40deg, 95%, 87%);
    --col-orange-50: hsl(45deg, 100%, 95%);

	--col-green-900: #064E3B;
	--col-green-800: #065F46;
	--col-green-700: #047857;
	--col-green-600: #059669;
	--col-green-500: #10B981;
	--col-green-400: #34D399;
	--col-green-300: #6EE7B7;
	--col-green-200: #A7F3D0;
	--col-green-100: #D1FAE5;
	--col-green-50: #ECFDF5;

	--col-red-900: #7F1D1D;
	--col-red-800: #991B1B;
	--col-red-700: #B91C1C;
	--col-red-600: #DC2626;
	--col-red-500: #EF4444;
	--col-red-400: #F87171;
	--col-red-300: #FCA5A5;
	--col-red-200: #FECACA;
	--col-red-100: #FEE2E2;
	--col-red-50: #FEF2F2;

    --col-red: #bf5b5b;
    /*--col-green-500: #3cc41e;*/
    /*--col-orange-500: #e67220;*/
    /*--col-blue-500: #0088ff;*/

    /* Font sizes used */
    --fs-8: 8pt;
    --fs-9: 9pt;
    --fs-11: 11pt;
    --fs-13: 13pt;
    --fs-16: 16pt;
    --fs-18: 18pt;
    --fs-24: 24pt;

    /* Font stacks */
    --font-stack-default: Lato, sans-serif;

    /* Units */
    --u-1: 1px;
    --u-2: 2px;
    --u-4: 4px;
    --u-6: 6px;
    --u-8: 8px;
    --u-12: 12px;
    --u-16: 16px;
    --u-24: 24px;
    --u-32: 32px;
    --u-48: 48px;
    --u-64: 64px;
    --u-96: 96px;
    --u-128: 128px;
    --u-192: 192px;
    --u-256: 256px;
    --u-384: 384px;
    --u-512: 512px;
    --u-768: 768px;
    --u-960: 960px;
    --u-1200: 1200px;
    --u-1600: 1600px;
    --u-2400: 2400px;

}

.green {
    color: var(--col-green-500);
}

.red {
    color: var(--col-red);
}

* {
    box-sizing: border-box;
}

a {
    color: var(--col-primary-500);
    text-decoration: none;
}

a:hover {
    color: var(--col-primary-600);
}

html, body {
    margin: 0;
    padding: 0;
    font-family: sans-serif;
    color: var(--col-grey-800);
    font-size: var(--fs-11);
    line-height: 1.35em;
}

.app {
    display: flex;
    flex-direction: row;
    height: 100vh;
}

.app-content {
    position: relative;
    overflow-y: auto;
    flex: 1;
    align-self: stretch;
}

.scl-sidebar {
    width: var(--u-48);
    height: 100vh;
    background: var(--col-primary-900);
    color: var(--col-primary-100);
    box-shadow: 0 0 var(--u-4) rgba(0, 0, 0, 0.3), 0 0 var(--u-16) rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
}

.scl-sidebar-content {
    flex: 1;
    display: flex;
    flex-direction: column;
    overflow-y: auto;
    padding-top: var(--u-16);
}

.scl-sidebar-link {
    display: flex;
    height: var(--u-48);
    line-height: var(--u-48);
    padding: 0 var(--u-12);
    color: var(--col-primary-100);
    text-decoration: none;
    border-left: 4px solid transparent;
    transition: border-left-color 0.15s ease-out, background 0.15s ease-out;
}

.scl-sidebar-link.active {
    border-left: var(--u-4) solid var(--col-primary-500);
    background: var(--col-primary-800);
}

.scl-sidebar-link:hover {
    border-left: var(--u-4) solid var(--col-primary-300);
    background: var(--col-primary-800);
}

.scl-sidebar-link-icon {
    /*padding-right: var(--u-12);*/
    width: 24px;
    margin-right: var(--u-4);
    text-align: center;
}

.login-info {
    /*border-top: 2px solid var(--col-grey-200);*/
    box-shadow: 0 0 var(--u-8) rgba(0, 0, 0, 0.1);
    padding: var(--u-16);
    background: var(--col-primary-800)
}

.login-info-company {
    font-style: italic;
}

.button-area {
    display: flex;
    flex-direction: row-reverse;
    padding-top: var(--u-16);
    gap: var(--u-16);
}

.button {
    display: block;
    height: var(--u-32);
    line-height: var(--u-32);
    min-width: var(--u-32);
    text-align: center;
    padding: 0 var(--u-8);
    margin: 0;
    background: var(--col-grey-100);
    color: var(--col-grey-700);
    font-size: inherit;
    font-weight: bold;
    border-radius: var(--u-4);
    box-shadow: 0 var(--u-1) var(--u-2) rgba(0, 0, 0, 0.05);
    transition: all 0.15s ease;
    border: 1px solid var(--col-grey-200);
    text-decoration: none;
}

.button-group {
    display: flex;
    flex-direction: row;
}

.button-group > .scl-button {
    border-radius: 0;
    border-right: 0;
}

.button-group > .scl-button:first-of-type,
.button-group > .scl-button:first-child {
    border-top-left-radius: var(--u-4);
    border-bottom-left-radius: var(--u-4);
}

.button-group > .scl-button:last-of-type,
.button-group > .scl-button:last-child {
    border-top-right-radius: var(--u-4);
    border-bottom-right-radius: var(--u-4);
    border-right: 1px solid var(--col-grey-200);
}

.button-group > .scl-button.scl-button-primary:last-of-type,
.button-group > .scl-button.scl-button-primary:last-child {
    border-right: 1px solid var(--col-primary-600);
}

.button:not(:disabled):not(.disabled):hover {
    box-shadow: 0 var(--u-1) var(--u-2) rgba(0, 0, 0, 0.1), 0 var(--u-1) var(--u-16) rgba(0, 0, 0, 0.07);
    background: var(--col-grey-200);
    color: var(--col-grey-800);
    border-color: var(--col-grey-300);
}

.button:not(:disabled):not(.disabled):active {
    box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.1);
    background: var(--col-grey-200);
    color: var(--col-grey-800);
    border-color: var(--col-grey-300);
}

.button.primary,
.button-group > .button.primary:last-of-type {
    background: var(--col-primary-500);
    color: white;
    border-color: var(--col-primary-600);
}

.button.primary:not(:disabled):not(.disabled):hover,
.button-group > .button.primary:not(:disabled):not(.disabled):hover:last-of-type {
    background: var(--col-primary-600);
    border-color: var(--col-primary-700);
    color: white;
}

.button:disabled,
.button.disabled {
    opacity: 0.5;
}

a.disabled:hover {
    color: inherit !important;
    pointer-events: none;
}

.page {
    padding: 16px 24px;
}

.app-ui {
    max-width: var(--u-960);
    padding: var(--u-16);
    margin: 0 auto;
}

.app-ui-fw {
    padding: var(--u-16);
}

.invisible {
    display: none;
}

.print-only {
    display: none;
}

@media print {
    .print-only {
        display: initial;
    }
}

.tooltip {
    position: absolute;
    bottom: calc(100% + var(--u-4));
    left: 50%;
    background: #222;
    transform: translateX(-50%);
    min-width: 50px;
    width: max-content;
    max-width: 240px;
    z-index: 200;
    text-align: center;
    color: white;
    font-weight: normal;
    font-size: 13px;
    padding: var(--u-4) var(--u-8);
    text-transform: initial;
    border-radius: 3px;
    word-wrap: break-word !important;
    white-space: normal;
    line-height: 1.2em;
    transition: opacity 0.3s ease-out;
    pointer-events: none;
}

.tooltip.right-overflow {
    max-width: 140px;
    left: -100%;
}

.tooltip::after {
    content: '';
    width: 0;
    height: 0;
    position: absolute;
    bottom: -5px;
    left: calc(50% - 5px);
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid var(--col-grey-800);
}

.tooltip.right-overflow::after {
    left: 80%;
}

.hover-bubble {
    position: relative;
}

.hover-bubble .tooltip {
    display: none;
}

.hover-bubble:hover .tooltip {
    display: block;
}

.auth {
    background: var(--col-grey-100);
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: var(--u-24);
}

.login-frame {
    background: #fff;
    width: 100%;
    border-radius: 2px;
    max-width: var(--u-384);
    padding: var(--u-24);
    box-shadow: var(--u-1) var(--u-1) var(--u-4) rgba(0, 0, 0, 0.07), var(--u-2) var(--u-2) var(--u-24) rgba(0, 0, 0, 0.07);
}

.auth .atrocit-logo {
    max-width: calc(var(--u-384) * 0.6);
    height: auto;
}

hr {
    border: 0;
    background: 0;
    border-bottom: 2px solid var(--col-primary-500);
    width: 100%;
}

.__react_component_tooltip.__react_component_tooltip {
    padding: var(--u-4) var(--u-8);
}

#printWindow {
    top: -100% !important;
    left: -100% !important;
    display: none;
}

.print-preview-page {
    page-break-after: always;
    min-height: 297mm;
    padding: 15mm 20mm;
    border-bottom: 1px solid var(--col-grey-100);
}

@media print {
    @page {
      size: A4;
      margin-top: 15mm;
      margin-bottom: 20mm;
    }

    .print-preview-page {
        min-height: 0;
        padding: 0;
        border-bottom: none;
    }
}