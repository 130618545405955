form {
    margin: 0;
    padding: 0;
}

.scl-form-element {
    width: 100%;
}

.table .scl-button.scl-button,
.table .scl-form-element {
    min-height: 24px;
}

.react-select__control.react-select__control {
    border-radius: 0;
    min-height: auto;
    font-size: inherit;
    font-family: inherit;
    color: inherit;
}

.react-select__input-container.react-select__input-container {
    margin: 0;
    padding: 0 2px;
    font-size: var(--fs-11);
    color: var(--col-grey-800);
}

.react-select__indicators.react-select__indicators {
    height: var(--u-24);
    min-height: auto;
}

.form-element, .react-select__control.react-select__control {
    border: 1px solid var(--col-grey-200);
    height: var(--u-24);
    width: 100%;
    font-size: var(--fs-11);
    color: var(--col-grey-800);
}

.form-element:focus,
.form-element:active,
.react-select__control--is-focused.react-select__control--is-focused {
    border-color: var(--col-primary-500);
    box-shadow: 0 0 2px var(--col-primary-500);
    outline: none;
}

.react-select__control.react-select__control > * {
    height: 100%;
}

.react-select__value-container.react-select__value-container {
    margin: 0;
    position: relative;
    padding: 0;
}

.react-select__menu.react-select__menu {
    border-radius: 0;
    border: 1px solid var(--col-grey-500);
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.3), 1px 2px 16px rgba(0, 0, 0, 0.15);
}

.react-select__option.react-select__option {
    min-height: var(--u-24);
    padding: 0 var(--u-4);
    display: flex;
    align-items: center;
}

.react-select__option--is-selected.react-select__option--is-selected {
    background: var(--col-primary-700);
}

.label {
    text-transform: uppercase;
    color: var(--col-primary-500);
    font-weight: bold;
    font-size: var(--fs-9);
}

label {
    display: block;
    padding-bottom: var(--u-16);
}

.form-element-with-icon {
    position: relative;
    display: flex;
    flex-direction: row;
    align-items: stretch;
}

.form-element-with-icon .form-element {
    border-right: none;
    z-index: 0;
}

.form-element-icon {
    border: 1px solid var(--col-grey-200);
    border-left: none;
    height: var(--u-24);
    display: flex;
    align-items: center;
    padding: 0 var(--u-4);
    line-height: 24px;
    color: var(--col-grey-500);
}

.form-element:focus + .form-element-icon,
.form-element:active + .form-element-icon {
    border-color: var(--col-primary-500);
    border-left: none;
}

input[type=checkbox]{
    height: 0;
    width: 0;
    visibility: hidden;
}

.toggle-label {
    cursor: pointer;
    text-indent: -9999px;
    width: 40px;
    height: 24px;
    background: grey;
    display: block;
    border-radius: 100px;
    position: relative;
}

.toggle-label:after {
    content: '';
    position: absolute;
    top: 3px;
    left: 3px;
    width: calc(24px - 3px - 3px);
    height: calc(24px - 3px - 3px);
    background: #fff;
    border-radius: 90px;
    transition: 200ms;
    /*transform: translateX(-100%);*/
}

.toggle-label-checked {
    background: var(--col-primary-500);
}

.toggle-label-checked:after {
    transform: translateX(calc(34px - 100%));
}

.toggle-label:active:after {
    width: 24px;
}

/* Big Multi selector stuff */
.big-multi-selector {
    display: grid;
    grid-template-columns: 1fr;
    gap: var(--u-8);
    padding-bottom: var(--u-16);
}

.big-multi-selector-option {
    display: grid;
    grid-template-columns: var(--u-48) 1fr;
    gap: var(--u-8);
    padding: var(--u-12) var(--u-8) var(--u-12) 0;
    /*padding-right: var(--u-16);*/
    align-items: center;
    border: 1px solid var(--col-grey-200);
    background: var(--col-grey-100);
    color: var(--col-grey-700);
    border-radius: var(--u-4);
}

.big-multi-selector-option .subtext {
    color: var(--col-grey-600);
}

.big-multi-selector-option-selected {
    background: var(--col-primary-100);
    border-color: var(--col-primary-200);
    color: var(--col-primary-800);
}

.big-multi-selector-option-selected .subtext {
    color: var(--col-primary-700);
}

.big-multi-selector-option-title {
    font-weight: bold;
}

.big-multi-selector-option-radio {
    margin: 0 auto;
}

.big-multi-selector-option-radio:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: #fff;
    content: '';
    display: inline-block;
    visibility: visible;
    box-shadow: 0 0 0 3px inset #fff;
    border: 1px solid var(--col-grey-200);
    outline: none;
}

input[type='radio']:checked:after {
    width: 15px;
    height: 15px;
    border-radius: 15px;
    top: -2px;
    left: -1px;
    position: relative;
    background-color: var(--col-primary-500);
    content: '';
    display: inline-block;
    visibility: visible;
    box-shadow: 0 0 0 3px inset #fff;
    border: 1.5px solid var(--col-primary-500);
    outline: none;
}

input[type='radio']:focus {
    outline: none;
}

input[type='radio']:focus:after {
    box-shadow: 0 0 0 3px inset #fff, 0 0 0 1px var(--col-primary-200);
}

.file-drop-area {
    display: block;
    min-height: var(--u-128);
    border: 4px dashed var(--col-grey-300);
    border-radius: 2px;
    background: var(--col-grey-100);
    position: relative;
}

.file-drop-target {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    text-align: center;
    color: var(--col-grey-500);
    text-transform: uppercase;
    font-weight: bold;
    font-size: var(--fs-11);
    line-height: 1.3em;
    padding: var(--u-8);
}

.file-drop-target .fa {
    display: inline-block;
    padding-bottom: var(--u-12);
    color: var(--col-grey-500);
    font-size: var(--fs-18);
}

.warning {
    background: var(--col-orange-100);
    border-left: 4px solid var(--col-orange-500);
    padding: var(--u-8);
    color: var(--col-orange-800);
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: var(--u-8);
    width: auto;
    max-width: 100%;
}

.warning-icon-compartment {
    padding-right: var(--u-4);
    padding-left: var(--u-4)
}

.warning-message {
    padding: 0 var(--u-8);
}

.warning .fa {
    color: var(--col-orange-500);
}

/* Hue wheel picker */
.hue-picker {
    display: flex;
    align-items: center;
    padding: var(--u-4);
}

.hue-picker-wheel {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    background: conic-gradient(red, #ff8000, yellow, #80ff00, lime, #00ff80, aqua, #0080ff, blue, #8000ff, fuchsia, #ff0080, red);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25);
    position: relative;
}

.hue-picker-wheel-cover-wrapper {
    position: absolute;
    inset: 0;
    padding: var(--u-16);
}

.hue-picker-wheel-cover {
    border-radius: 50%;
    width: 100%;
    height: 100%;
    background: #fff;
    box-shadow: 0 1px 4px rgba(0, 0, 0, 0.25);
}

.hue-picker-sample {
    position: relative;
    width: var(--u-32);
    height: var(--u-32);
    box-shadow: inset 0 1px 4px rgba(0, 0, 0, 0.25);
    border-radius: 4px;
    margin-left: var(--u-4);
}

.hue-picker-sample::after {
    right: 100%;
    top: 50%;
    border: solid transparent;
    content: "";
    height: 0;
    width: 0;
    position: absolute;
    pointer-events: none;
    border-color: rgba(136, 183, 213, 0);
    border-right-color: var(--col-grey-800);
    border-width: 4px;
    margin-top: -4px;
}