.bays {
    position: relative;
}

.bays-item {
    padding: var(--u-8);
    padding-bottom: var(--u-16);
}

.bays-item-selected {
    position: sticky;
    top: 0;
    bottom: 0;
    background: rgba(220, 220, 220, 0.8);
    backdrop-filter: blur(2px);
    border-radius: 4px;
    z-index: 10;
}

@keyframes pulse {
    0% { opacity: 1; }
    50% { opacity: 0.2; }
    100% { opacity: 1; }
}

.bays-item-hover-over {
    animation: 0.3s ease-in-out pulse infinite;
}

.bays-item-hover-over.bays-item-selected {
    animation: none;
}

.bays-item-title {
    text-transform: uppercase;
    font-size: var(--fs-9);
    color: var(--col-grey-700);
}

.bay {
    display: inline-block;
    border-collapse: collapse;
    font-family: 'News Cycle', sans-serif;
}

.bay-upper-deck {
    border-bottom: 3px solid var(--col-grey-400);
    display: flex;
    flex-direction: row;
    align-items: flex-end;
    padding-bottom: 4px;
    margin-bottom: 4px;
}

.bay-large .bay-upper-deck {
    padding-bottom: 8px;
    margin-bottom: 8px;
    border-bottom: 6px solid var(--col-grey-400);
}

.bay-lower-deck {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
}

.cell {
    display: table-cell;
    width: 10px;
    height: 10px;
    border: 1px solid var(--col-grey-700);
    position: relative;
    margin-top: -1px; /* for border overlap */
    margin-left: -1px; /* for border overlap */
    overflow: hidden;
    z-index: 5; /* should be higher than cell-add */
}

.cell.cell-add {
    background: var(--col-grey-50);
    border-color: var(--col-grey-200);
    color: var(--col-grey-200);
    align-content: center;
    text-align: center;
    position: relative;
    z-index: 1;
    transition: border-color 150ms ease, color 150ms ease;
    cursor: pointer;
}

.cell.cell-add:hover {
    border-color: var(--col-grey-300);
    color: var(--col-grey-300);
    z-index: 2;
}

.cell.cell-block-preview {
    background: var(--col-primary-50);
    border-color: var(--col-primary-200);
    color: var(--col-primary-200);
    transition: border-color 150ms ease, color 150ms ease;
    z-index: 3;
}

.cell.cell-block-preview:hover {
    background: var(--col-primary-100);
    border-color: var(--col-primary-200);
    color: var(--col-primary-300);
    transition: border-color 150ms ease, color 150ms ease;
    z-index: 3;
}

.bay-large .cell {
    width: 70px;
}

.bay-large .row {
    min-width: 69px; /* 1px less because border overlap of cell */
}

.bay-large .cell-height,
.bay-large .cell {
    height: 48px; /* 64 for 4 lines */

    &.cell-highcube {
        height: 54px; /* 72 for 4 lines*/
    }
}

.bay-large .cell-content {
    padding: 2px;
}

.cell.cell-forty-ghost .cell-content {
    opacity: 0.4;
}

.cell.cell-forty-ghost .cell-content {
    position: relative;
}

.cell.cell-forty-ghost .cell-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 0 L100 100 ' stroke='black' stroke-width='10'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
}

.cell.cell-faded-ghost .cell-content {
    opacity: 0.4;
    position: relative;
}

/* First diagonal (top left to bottom right) */
.cell.cell-faded-ghost .cell-content:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 0 L100 100 ' stroke='black' stroke-width='10'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
}

/* Second diagonal (top right to bottom left) */
.cell.cell-faded-ghost .cell-content:after {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M100 0 L0 100 ' stroke='black' stroke-width='10'/></svg>");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100% 100%, auto;
}

.cell.cell-dragging > * {
    opacity: 0.3;
}

.cell.cell-faded > * {
    pointer-events: none;
    opacity: 0.2;
}

@keyframes pulsing {
    0% { opacity: 1; }
    35% { opacity: 1; }
    65% { opacity: 0.5; }
    100% { opacity: 0.5; }
}

.cell.cell-highlighted {
    animation: pulsing 0.3s ease-in-out infinite alternate;
}

.bay-large .cell.cell-forty-ghost:not(.cell-faded) > * {
    opacity: 0.4;
}

.bay-large .cell.cell-forty-ghost .cell-content:before {
    background: url("data:image/svg+xml;utf8,<svg xmlns='http://www.w3.org/2000/svg' version='1.1' preserveAspectRatio='none' viewBox='0 0 100 100'><path d='M0 0 L100 100 ' stroke='black' stroke-width='2'/></svg>");
}

.rowtier-label {
    display: flex;
    min-width: var(--u-16);
    min-height: var(--u-16);
    flex-direction: row;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: none;
    font-size: 7pt;
    color: var(--col-grey-500);
}

.cell-blocked {
    position: absolute;
    inset: 0;
    z-index: 10;
    opacity: 0.3;
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.7) 0px, rgba(0, 0, 0, 0.7) 1px, transparent 1px, transparent 4px);
}

.cell-blocked-mini {
    position: absolute;
    inset: 0;
    z-index: 10;
    opacity: 0.5;
    background: repeating-linear-gradient(-45deg, rgba(0, 0, 0, 0.7) 0px, rgba(0, 0, 0, 0.7) 0.5px, transparent 0.5px, transparent 2px);
}

.cell-oversize-indicators {
    position: absolute;
    inset: 0;
    z-index: 0;
}

.cell-oversize-indicators > .cell-oversize-height {
    position: absolute;
    width: 100%;
    height: 3px;
    background: rgba(0, 0, 0, 0.4);
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.7) 0px, rgba(0, 0, 0, 0.7) 4px, transparent 4px, transparent 8px);
}

.cell-oversize-indicators > .cell-oversize-width-left {
    position: absolute;
    height: 100%;
    width: 3px;
    left: 0;
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.7) 0px, rgba(0, 0, 0, 0.7) 4px, transparent 4px, transparent 8px);
}

.cell-oversize-indicators > .cell-oversize-width-right {
    position: absolute;
    height: 100%;
    width: 3px;
    right: 0;
    background: repeating-linear-gradient(45deg, rgba(0, 0, 0, 0.7) 0px, rgba(0, 0, 0, 0.7) 4px, transparent 4px, transparent 8px);
}

.reefer-indicator {
    transform: rotate(-45deg);
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid var(--col-grey-100);
    top: calc(100% - 10px);
    left: calc(100% - 10px);
    background: #fff;
}

.flatrack-indicator {
    transform: rotate(-45deg);
    position: absolute;
    width: 20px;
    height: 20px;
    border: 1px solid var(--col-grey-100);
    top: calc(100% - 10px);
    right: calc(100% - 10px);
    background: #fff;
}

.highcube-indicator {
    position: absolute;
    width: 12px;
    height: 12px;
    border-bottom-right-radius: 40px;
    border: 1px solid var(--col-grey-600);
    bottom: calc(100% - 11px);
    right: calc(100% - 11px);
    background: transparent;
}

.dgs-indicator {
    transform: rotate(-45deg);
    width: 7px;
    height: 7px;
    position: absolute;
    top: calc(50% - 3.5px);
    left: calc(50% - 3.5px);
    border: 1px solid rgba(0, 0, 0, 0.6);
}

.user-highlight-indicator {
    position: absolute;
    inset: 0;
    background-image: radial-gradient(rgba(255, 255, 255, 0.75) 30%, transparent 30%), radial-gradient(rgba(255, 255, 255, 0.75) 30%, transparent 30%);
    background-position: 0 0, 12px 12px;
    background-size: 24px 24px;
    background-color: rgba(0, 0, 0, 0);
}


.bay-large .dgs-indicator {
    transform: rotate(-45deg);
    position: absolute;
    top: calc(50% - (31px / 2));
    left: calc(50% - (31px / 2));
    width: 31px;
    height: 31px;
    border: 2px solid rgba(0, 0, 0, 0.2);
}

.pill {
    display: inline-flex;
    padding: 0 var(--u-2);
    border-radius: 0.5em;
    border: 1px solid var(--col-grey-500);
    line-height: 1em;
    align-items: center;
    letter-spacing: -0.3px;
}