.breadcrumbs {
    display: flex;
    flex-direction: row;
    padding-bottom: var(--u-16);
}

.breadcrumbs a {
    color: var(--col-grey-600);
    text-decoration: none;
}

.breadcrumbs a:hover {
    color: var(--col-grey-700);
    text-decoration: underline;
}

.breadcrumb-separator {
    padding: 0 var(--u-4);
}

.ReactModal__Overlay--after-open {
    animation: modalFadeInBackground 300ms ease;
    animation-fill-mode: backwards;
}

/*.ReactModal__Overlay--before-close {*/
/*    animation: modalFadeInBackground 150ms ease reverse;*/
/*    animation-fill-mode: backwards;*/
/*}*/

@keyframes modalFadeInBackground {
    from { background: rgba(0, 0, 0, 0); }
    to { background: rgba(0, 0, 0, 0.5); }
}

@keyframes modalPop {
    from {
        opacity: 0;
        transform: translateY(24px) scale(0.9);
    }

    to {
        opacity: 1;
        transform: translateY(0px) scale(1);
    }
}

.modal-card {
    position: relative;
    animation: modalPop 300ms ease;
    animation-fill-mode: backwards;
    min-width: 300px;
    background: #fff;
    border-radius: var(--u-4);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.10), 1px 2px 24px rgba(0, 0, 0, 0.2);
}

.modal-header.modal-header {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: 8px;
    padding-left: var(--u-16);
}

.modal-content {
    padding: var(--u-16);
}


.toolbar {
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: var(--col-grey-100);
    /*flex: 1;*/
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 0 16px rgba(0, 0, 0, 0.15);
    padding: var(--u-8) var(--u-16);
}

.advanced-toolbar {
    display: flex;
    background: var(--col-grey-100);
    box-shadow: 0 0 4px rgba(0, 0, 0, 0.15), 0 0 16px rgba(0, 0, 0, 0.15);
    padding: var(--u-8) var(--u-16);
}

.toolbar-separator {
    border-left: 1px solid var(--col-grey-200);
    height: calc(var(--u-32) - var(--u-8));
    margin-left: var(--u-4);
    margin-right: var(--u-4);
}

.ports-legend {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    padding: var(--u-16) 0;
}

.ports-legend-item {
    padding: var(--u-2) var(--u-4);
}

.card {
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1), 0px 3px 16px -8px rgba(0, 0, 0, 0.15);
    padding: var(--u-24) var(--u-32);
    background: var(--col-white);
}

.metric {
    text-align: center;
    padding-top: var(--u-32);
}

.metric-number {
    font-size: var(--fs-24);
}

.metric-description {
    color: var(--col-grey-600);
    text-transform: uppercase;
    font-size: var(--fs-9);
    display: inline-block;
    padding-top: var(--u-4);
}

@keyframes spin {
    from { transform: rotate(0deg); }
    to { transform: rotate(360deg); }
}

.loading-spinner {
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 8px solid var(--col-primary-200);
    border-bottom-color: var(--col-primary-600);
    animation: spin 650ms linear infinite;
}

.session-user-circle {
    border-radius: 50%;
    width: 48px;
    height: 48px;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--fs-13);
    color: white;
    box-shadow: 1px 2px 4px rgba(0, 0, 0, 0.1);
    margin-right: -6px;
    border: 2px solid #fff;
}

.session-user-circle:last-of-type {
    margin-right: 0;
}

.scl-big-multi-selector .scl-big-multi-selector-option-title.scl-big-multi-selector-option-title {
    margin-bottom: 0;
}